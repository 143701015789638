<template>
  <a-card :bordered="false">
    <div>
      <a-form :form="form" >
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {initialValue: data.name}, {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="资料" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input disabled v-decorator="['url', {initialValue: data.url}, {rules: [{required: true, message: '必填项，请填写信息'}, {max:512, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item v-bind="buttonCol">
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { collection_attachment_update, collection_attachment_list } from '@/api/trade_collection_attachment'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      data: {}
    }
  },
  mounted () {
    collection_attachment_list({ id: this.$route.params.attachment_id })
      .then(res => {
        console.log(res.data, '我是数据列表@')
        const list = res.data.entries
        if (list && list.length > 0) {
          this.data = list[0]
          this.id = this.data.id
        }
      })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        collection_attachment_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'name', 'url'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
